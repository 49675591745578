exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-company-about-js": () => import("./../../../src/pages/company/about.js" /* webpackChunkName: "component---src-pages-company-about-js" */),
  "component---src-pages-company-access-js": () => import("./../../../src/pages/company/access.js" /* webpackChunkName: "component---src-pages-company-access-js" */),
  "component---src-pages-company-cybernics-js": () => import("./../../../src/pages/company/cybernics.js" /* webpackChunkName: "component---src-pages-company-cybernics-js" */),
  "component---src-pages-company-founder-js": () => import("./../../../src/pages/company/founder.js" /* webpackChunkName: "component---src-pages-company-founder-js" */),
  "component---src-pages-company-index-js": () => import("./../../../src/pages/company/index.js" /* webpackChunkName: "component---src-pages-company-index-js" */),
  "component---src-pages-company-mission-js": () => import("./../../../src/pages/company/mission.js" /* webpackChunkName: "component---src-pages-company-mission-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-documents-js": () => import("./../../../src/pages/documents.js" /* webpackChunkName: "component---src-pages-documents-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-page-template-js": () => import("./../../../src/pages/page-template.js" /* webpackChunkName: "component---src-pages-page-template-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-products-cleaning-robot-js": () => import("./../../../src/pages/products/cleaning-robot.js" /* webpackChunkName: "component---src-pages-products-cleaning-robot-js" */),
  "component---src-pages-products-communication-js": () => import("./../../../src/pages/products/communication.js" /* webpackChunkName: "component---src-pages-products-communication-js" */),
  "component---src-pages-products-imaging-js": () => import("./../../../src/pages/products/imaging.js" /* webpackChunkName: "component---src-pages-products-imaging-js" */),
  "component---src-pages-products-index-js": () => import("./../../../src/pages/products/index.js" /* webpackChunkName: "component---src-pages-products-index-js" */),
  "component---src-pages-products-medical-device-hal-joint-js": () => import("./../../../src/pages/products/medical-device/hal-joint.js" /* webpackChunkName: "component---src-pages-products-medical-device-hal-joint-js" */),
  "component---src-pages-products-medical-device-hal-limb-js": () => import("./../../../src/pages/products/medical-device/hal-limb.js" /* webpackChunkName: "component---src-pages-products-medical-device-hal-limb-js" */),
  "component---src-pages-products-medical-device-hal-motion-principal-js": () => import("./../../../src/pages/products/medical-device/hal-motion-principal.js" /* webpackChunkName: "component---src-pages-products-medical-device-hal-motion-principal-js" */),
  "component---src-pages-products-non-medical-device-js": () => import("./../../../src/pages/products/non-medical-device.js" /* webpackChunkName: "component---src-pages-products-non-medical-device-js" */),
  "component---src-pages-recruit-js": () => import("./../../../src/pages/recruit.js" /* webpackChunkName: "component---src-pages-recruit-js" */),
  "component---src-pages-research-js": () => import("./../../../src/pages/research.js" /* webpackChunkName: "component---src-pages-research-js" */),
  "component---src-pages-ssr-test-js": () => import("./../../../src/pages/ssr-test.js" /* webpackChunkName: "component---src-pages-ssr-test-js" */),
  "component---src-pages-training-athletes-js": () => import("./../../../src/pages/training/athletes.js" /* webpackChunkName: "component---src-pages-training-athletes-js" */),
  "component---src-pages-training-elderly-js": () => import("./../../../src/pages/training/elderly.js" /* webpackChunkName: "component---src-pages-training-elderly-js" */),
  "component---src-pages-treatment-cvd-js": () => import("./../../../src/pages/treatment/cvd.js" /* webpackChunkName: "component---src-pages-treatment-cvd-js" */),
  "component---src-pages-treatment-faq-js": () => import("./../../../src/pages/treatment/faq.js" /* webpackChunkName: "component---src-pages-treatment-faq-js" */),
  "component---src-pages-treatment-index-js": () => import("./../../../src/pages/treatment/index.js" /* webpackChunkName: "component---src-pages-treatment-index-js" */),
  "component---src-pages-treatment-pnd-js": () => import("./../../../src/pages/treatment/pnd.js" /* webpackChunkName: "component---src-pages-treatment-pnd-js" */),
  "component---src-pages-treatment-spinal-js": () => import("./../../../src/pages/treatment/spinal.js" /* webpackChunkName: "component---src-pages-treatment-spinal-js" */),
  "component---src-pages-treatment-testimonials-js": () => import("./../../../src/pages/treatment/testimonials.js" /* webpackChunkName: "component---src-pages-treatment-testimonials-js" */),
  "component---src-pages-treatment-znb-js": () => import("./../../../src/pages/treatment/znb.js" /* webpackChunkName: "component---src-pages-treatment-znb-js" */),
  "component---src-templates-media-news-js": () => import("./../../../src/templates/mediaNews.js" /* webpackChunkName: "component---src-templates-media-news-js" */),
  "component---src-templates-publication-js": () => import("./../../../src/templates/publication.js" /* webpackChunkName: "component---src-templates-publication-js" */),
  "component---src-templates-publication-plain-js": () => import("./../../../src/templates/publication-plain.js" /* webpackChunkName: "component---src-templates-publication-plain-js" */),
  "component---src-templates-recruit-js": () => import("./../../../src/templates/recruit.js" /* webpackChunkName: "component---src-templates-recruit-js" */)
}

